import { useContext } from 'react';
import { FilterDispatchContext } from '../../../../context/filterStatementContext';
import { FilterGroupActionType, FilterNodeState } from '../../../../reducers/filterStatement/filterStatementReducer';
import { AppliedFilter } from '../appliedFilters/AppliedFilter';
import { Popover, Transition } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { Fragment } from 'react';

export const RootFilter: React.FC<{ filter: FilterNodeState | undefined }> = ({ filter }) => {
  if (!filter) return <></>;
  if (filter.type !== 'collection') {
    throw new Error('Root filter must be a collection');
  }
  return (
    <>
      {filter.items.map((item, index) => (
        <div className="h-8 flex flex-row gap-x-2">
          {index > 0 && (
            <span key={`operator-${index}`} className="flex items-center relative group">
              <OperatorSelector filter={filter} />
            </span>
          )}
          <span key={`filter-${item.id}`} className="flex bg-gray-200 px-3 rounded-3xl relative group">
            <AppliedFilter filter={item} key={item.id} />
          </span>
        </div>
      ))}
    </>
  );
};

export const OperatorSelector: React.FC<{ filter: FilterNodeState }> = ({ filter }) => {
  const dispatch = useContext(FilterDispatchContext);

  const options = [
    { label: 'and', value: 'and' },
    { label: 'or', value: 'or' },
  ];

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <Float
          placement="bottom-start"
          offset={6}
          shift={true}
          flip={{
            fallbackPlacements: ['top-start'],
            padding: 8,
          }}
          portal={true}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Button className="focus:outline-none">
            <span className="rounded-md font-semibold text-blueberry text-sm hover:text-raspberry transition-all duration-150 ease-out">
              {filter.operator.toLowerCase()}
            </span>
          </Popover.Button>

          <Popover.Panel className="w-24 z-50" data-testid="operator-selector-dropdown-options">
            <div className="flex flex-col font-sofiapro rounded-lg border-2 border-gray-200 bg-white shadow-lg">
              {options.map((option) => (
                <button
                  key={option.value}
                  className={`w-full px-4 py-2 text-left h-full font-medium flex flex-row border-b border-gray-200 items-center justify-between hover:bg-gray-100 duration-100 first:rounded-t-lg last:border-none last:rounded-b-lg`}
                  onClick={() => {
                    dispatch({
                      type: FilterGroupActionType.UpdateCollectionOperator,
                      payload: { id: filter.id, operator: option.value },
                    });
                    close();
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </Popover.Panel>
        </Float>
      )}
    </Popover>
  );
};
