import Tippy from '@tippyjs/react';
import { classNames } from '../../../v2/util';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

export function GroupCount(props: { loading: boolean; filteredGroupCount: number; amountOfGroups: number | undefined }) {
  return (
    <Tippy
      content={
        <p>
          This indicates the currently <b>filtered</b> group count compared to the total group count.
        </p>
      }
    >
      <div className={classNames('flex flex-row gap-x-2 w-fit min-w-[200px]')}>
        <p className="text-sm text-gray-500">
          {props.loading || !props.amountOfGroups ? (
            <span className="inline-block w-48 h-6 bg-gray-200 rounded animate-pulse" />
          ) : (
            <>
              <b className="text-blueberry">Showing {props.filteredGroupCount}</b> of {props.amountOfGroups} groups
            </>
          )}
        </p>
      </div>
    </Tippy>
  );
}
