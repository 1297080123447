import { useContext, useEffect, useState } from 'react';
import { FilterGroupActionType, FilterNodeState, FilterStatementSchema } from '../../../../reducers/filterStatement/filterStatementReducer';
import { AppliedFilterActions } from './AppliedFilterActions';
import { adaptForDisplay, adaptForOperator } from '../operatorUtils';
import Tippy from '@tippyjs/react';
import { FilterFieldValue, useGetFilterFieldDisplayLazyQuery } from '../../../../generated/graphql';
import { LoadingDots } from '../filterBar/DateFilterComponent';
import { FilterConfigurationPopup } from '../filterConfiguration/FilterConfiguration';
import { FilterContext, FilterDispatchContext } from '../../../../context/filterStatementContext';
import { truncateAndEllipsis } from '../../../../v2/util';

export const AppliedStatementFilter: React.FC<{ filter: FilterNodeState }> = ({ filter }) => {
  if (filter.type !== 'statement') throw new Error('AppliedStatementFilter must be a statement');
  const dispatch = useContext(FilterDispatchContext);
  const { teamId } = useContext(FilterContext);

  const [getFilterFieldDisplay, { loading }] = useGetFilterFieldDisplayLazyQuery({});
  const [displayName, setDisplayName] = useState('');
  const [displayValue, setDisplayValue] = useState<FilterFieldValue[]>([]);
  const [showFilterStatementEditor, setShowFilterStatementEditor] = useState(false);

  useEffect(() => {
    /**
     * Why we do this?
     *
     * So the filter field and filter field value are not what we want to show on the UI.
     * We need to load in the display name for both the field and the value if these are not already set.
     *
     * This query loads them in and sets the state for the display name and display value.
     */
    getFilterFieldDisplay({
      variables: {
        fieldName: filter.fieldName,
        filterValue: filter.value,
        teamId: teamId,
      },
      onCompleted: (data) => {
        setDisplayValue(data.getFilterFieldDisplay.values);
        setDisplayName(data.getFilterFieldDisplay.displayName);
      },
    });
  }, [filter.fieldName, filter.value]);

  const onFilterStatementEditComplete = (node: FilterStatementSchema) => {
    dispatch({
      type: FilterGroupActionType.UpdateFilterNode,
      payload: {
        // set the id so we update the correct node
        id: filter.id,
        filterNode: node,
      },
    });
  };

  if (loading) return <LoadingDots />;
  return (
    <Tippy
      className="pointer-events-auto" // this allows the tooltip to be interacted with even if the filter is disabled
      content={
        <div>
          <span className="mr-1 text-white ">{displayName}</span>
          <strong className="text-gray-400">
            {/* We're going to want to show the values in a way that's easy to read */}
            {adaptForDisplay(filter.operator)} <span className="text-white">{displayValue.map((value) => value.displayName).join(', ')}</span>
          </strong>
        </div>
      }
    >
      <div className="flex flex-row items-center">
        <AppliedFilterActions filter={filter} />

        <FilterConfigurationPopup onComplete={onFilterStatementEditComplete} selectedFieldName={filter.fieldName}>
          <button
            id="applied-filter-button"
            className="text-left text-sm lg:whitespace-nowrap hover:*:text-raspberry hover:cursor-pointer overflow-hidden text-ellipsis"
            onClick={() => {
              setShowFilterStatementEditor(true);
            }}
          >
            <span className="mr-1 text-gray-500">{truncateAndEllipsis(displayName, 15)}</span>
            <strong className="text-blueberry ">
              {adaptForDisplay(filter.operator)} {truncateAndEllipsis(displayValue.map((value) => value.displayName).join(', '), 25)}
            </strong>
          </button>
        </FilterConfigurationPopup>
      </div>
    </Tippy>
  );
};
