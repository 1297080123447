import {
  ArrowDownTrayIcon,
  BookmarkIcon,
  Cog6ToothIcon,
  PaperClipIcon,
  PencilSquareIcon,
  SparklesIcon,
  TrashIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import 'tippy.js/themes/light.css';
import { FilterContext } from '../../context/filterStatementContext';
import { exportGroupWithProgressUpdate } from '../../eventHandlers/exports/groupExport';
import { ProgressState, Status } from '../../exports/ProgressMonitor';
import { Action, Group_Trending, Resource, useGetGroupLazyQuery, useGetGroupStatusLazyQuery, useTogglePinGroupMutation } from '../../generated/graphql';
import AppContext, { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { PermissionsContext } from '../../v2/contexts/PermissionsContext';
import { writeToastMessage } from '../../v2/hooks/GroupHook';
import { truncate } from '../../v2/util';
import SettingsMenu, { ISettingsItem, MenuAlign } from '../baseComponents/SettingsMenu';
import { TaxonomyContext } from '../context/TaxonomyContext';
import { TaxonomyDispatchContext } from '../context/TaxonomyDispatchContext';
import { GroupLinkBuilder } from '../lib/GroupLinkBuilder';
import { getGroupPageUrl } from '../lib/groups';
import CreatedBy from './groups/toolbar/CreatedBy';
import { ProgressBar } from './ProgressBar';
import { CopyGroupLink } from './taxonomy/CopyGroupLink';
import { GroupBreadcrumbs } from './taxonomy/GroupBreadcrumbs';
import { GroupBuildProgress } from './taxonomy/GroupBuildProgress';
import { GroupMentionData } from './taxonomy/GroupMentionData';
import { NewBadge } from './taxonomy/NewBadge';
import { PinGroupLink } from './taxonomy/PinGroupLink';
import { ToggleExpandGroup } from './taxonomy/ToggleExpandGroup';
import { TrendArrow } from './taxonomy/TrendArrow';
import { Creator, TaxonomyGroup } from '../reducers/taxonomy';
interface TaxonomyFolderProps {
  flatGroup: boolean;
  taxonomyGroup: TaxonomyGroup;
  trending: Group_Trending | null | undefined;
  children: any[];
  teamId: number;
  setActiveId: (id: string | undefined) => void;
  itemRef: any;
  setCurrentGroupId: (groupId: string) => void;
  zIndex: string;
  depth?: number;
}

function GroupSettingsMenu(props: { onClick: (e: SyntheticEvent) => void; settings: ISettingsItem[] }) {
  return (
    <div
      className="p-2 rounded-full relative  hover:cursor-pointer hover:bg-slate-900/25 "
      role="button"
      onClick={props.onClick}
      data-cy="toggle-taxonomy-folder-menu"
    >
      <SettingsMenu settings={props.settings} center={true} align={MenuAlign.RIGHT}>
        <Tippy theme="light" delay={200} content={<p className="text-center">Options</p>}>
          <Cog6ToothIcon className=" h-5 w-5 z-10" />
        </Tippy>
      </SettingsMenu>
    </div>
  );
}

/** 
  TaxonomyFolder is used to display a folder in the taxonomy view.
  * @param {boolean} flatGroup - Whether the group should be flat (no toggle / collapse + breadcrumbs) 
                               - if flatGroup is True, taxonomyGroup should only contain one group.
  * @param {TaxonomyGroup} taxonomyGroup - The taxonomy group to display
  * @param {boolean} trending - The trending status of the group
  * @param {JSX.Element[]} children - The children of the group
  * @param {number} teamId - The team id
  * @param {function} setActiveId - The function to set the active id
  * @param {React.RefObject<Element>} itemRef - The ref of the item
  * @param {function} setCurrentGroupId - The function to set the current group id
  * @param {function} updateProgress - The function to update the progress of the group
  * @param {function} replaceOrAddToSearchGroups - The function to replace or add to search groups
  * @param {string} zIndex - The z index of the group
  * @param {number} depth - The depth of the group
**/
export const TaxonomyFolder = ({
  flatGroup,
  taxonomyGroup,
  trending,
  children,
  teamId,
  setActiveId,
  itemRef,
  setCurrentGroupId,
  zIndex,
  depth,
}: TaxonomyFolderProps) => {
  const taxonomy = useContext(TaxonomyContext);
  const dispatch = useContext(TaxonomyDispatchContext);

  const [togglePinGroupMutation] = useTogglePinGroupMutation();

  const handlePinGroup = (e: SyntheticEvent) => {
    e.stopPropagation();
    setPinLoading(true);
    togglePinGroupMutation({
      variables: { teamId, groupId: taxonomyGroup.id },
      onCompleted: async (data) => {
        if (taxonomy.get(taxonomyGroup.id)) {
          const current = taxonomy.get(taxonomyGroup.id)!;
          dispatch({ type: 'togglePinGroup', payload: { groupId: taxonomyGroup.id, pinnedByUser: !current.isPinnedByUser } });
          setPinLoading(false);
        }
      },
    });
  };

  const { hasPermission } = useContext(PermissionsContext);

  const { curOrgId: orgId } = useValidTeamAppContext();
  const navigate = useNavigate();
  const [exportProgress, updateExportProgress] = useState<ProgressState>({ status: Status.idle, percent: 0 });

  const filterState = useContext(FilterContext);

  const copyLinkToClipboard = () => {
    const groupLinkBuilder = new GroupLinkBuilder(teamId, orgId);
    const groupLink = groupLinkBuilder.fromConsumable(filterState.filterConsumable);
    navigator.clipboard.writeText(groupLink);
  };

  const settings: ISettingsItem[] = [
    {
      name: 'Pin Group',
      id: 1,
      group: 'pin',
      htmlId: 'pin-group',
      icon: <BookmarkIcon className="h-5 w-5" />,
      disabled: !hasPermission(Resource.PinnedGroups, Action.Update),
      onClick: handlePinGroup as () => void,
    },
    {
      name: 'Export to CSV',
      id: 2,
      group: 'actions',
      htmlId: 'export-group-csv',
      icon: <ArrowDownTrayIcon className="h-5 w-5" />,
      onClick: () => {
        if (taxonomyGroup?.title) {
          exportGroupWithProgressUpdate(
            { groupTitle: taxonomyGroup.title, teamId, groupIds: [taxonomyGroup.id], filterConsumable: filterState.filterConsumable },
            exportProgress,
            updateExportProgress
          );
        } else toast.error('Could not export data: Group is invalid or not defined.');
      },
    },
    {
      name: 'Link to Group',
      id: 3,
      group: 'actions',
      htmlId: 'link-group',
      icon: <PaperClipIcon className="h-5 w-5" />,
      onClick: () => {
        copyLinkToClipboard();
        writeToastMessage('Copied group link to clipboard');
      },
    },
    {
      name: 'Edit Taxonomy',
      id: 4,
      group: 'edit',
      htmlId: 'edit-taxonomy',
      disabled: !hasPermission(Resource.Taxonomy, Action.Update),
      icon: <PencilSquareIcon className="h-5 w-5" />,
      onClick: () => {
        sessionStorage.setItem('savedScrollPosition', window.scrollY.toString());
        navigate(getGroupPageUrl(teamId, orgId, taxonomyGroup.id));
      },
    },
    {
      name: 'Delete Group',
      textColor: 'failure',
      id: 5,
      group: 'delete',
      htmlId: 'delete-group',
      disabled: !hasPermission(Resource.Groups, Action.Delete),
      icon: <TrashIcon className="h-5 w-5" />,
      onClick: () => {
        setCurrentGroupId(taxonomyGroup.id);
      },
    },
  ];
  const [pinLoading, setPinLoading] = useState(false);

  const { app } = useContext(AppContext);
  const [getGroupQuery, _] = useGetGroupLazyQuery({});

  const fetchAndAddSearchGroup = (searchGroupId: string) => {
    getGroupQuery({
      variables: { teamId, groupId: searchGroupId, teamUuid: app?.currentUuid, filterStatement: filterState?.filterConsumable ?? {} },
      onCompleted(data) {
        dispatch({ type: 'addGroup', payload: { group: data?.getGroup } });
      },
    });
  };

  /**
   * When groups are built, we allow the user to see the progress
   * When the group is done building, it should be added to the top of the taxonomy view
   */
  const [getSearchGroupStatus, { startPolling, stopPolling }] = useGetGroupStatusLazyQuery({
    variables: { teamId, groupId: taxonomyGroup.id ?? -1, teamUuid: app?.currentUuid },
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (data.getGroup.processing) {
        dispatch({ type: 'updateProgress', payload: { groupId: taxonomyGroup.id, progress: data.getGroup.progress } });
      } else {
        //Processing finished, fetch and store the SearchGroup
        fetchAndAddSearchGroup(taxonomyGroup.id);
        stopPolling();
      }
    },
  });

  useEffect(() => {
    if (taxonomyGroup.processing) startPolling(10_000);
  }, []);

  return (
    <>
      <div
        className={`flex flex-row items-center gap-x-3 ${taxonomyGroup.processing ? '' : 'cursor-pointer'} relative group ${zIndex} w-full mt-1`}
        ref={itemRef}
      >
        {!flatGroup && <ToggleExpandGroup taxonomyGroup={taxonomyGroup} depth={depth} />}
        <div
          id="group-preview-card"
          data-cy="taxonomy-folder"
          onClick={(event) => {
            if (taxonomyGroup.processing) return;

            sessionStorage.setItem('savedScrollPosition', window.scrollY.toString());
            if (event.metaKey || event.ctrlKey) {
              sessionStorage.setItem('savedScrollPosition', window.scrollY.toString());
              window.open(getGroupPageUrl(teamId, orgId, taxonomyGroup.id), '_blank');
            } else {
              navigate(getGroupPageUrl(teamId, orgId, taxonomyGroup.id));
            }
          }}
          className={`w-full bg-white text-blueberry ${
            !taxonomyGroup.processing ? 'hover:bg-blueberry hover:text-white hover:shadow-lg' : ''
          } duration-150 transition-colors shadow-[0_1px_2px_rgb(0,0,0,0.1)] border-2 rounded-lg p-3 flex flex-row justify-between items-center gap-x-1`}
        >
          <div className="flex flex-col gap-x-2">
            {flatGroup && (
              <span className="flex flex-row items-center gap-x-2 w-full mb-1">
                <GroupBreadcrumbs group={taxonomyGroup} />
              </span>
            )}

            <div className="justify-start flex flex-row items-center gap-x-3 ">
              <div className="flex flex-col justify-start">
                <div className="flex flex-row gap-x-2 justify-start items-center">
                  <h3 className="text-lg font-semibold flex justify-start items-center gap-x-2">{truncate(taxonomyGroup.title ?? '', 75)}</h3>
                  <TrendArrow trending={trending} />
                  <NewBadge isNew={taxonomyGroup.isNew} />
                </div>
                {!taxonomyGroup.processing && (
                  <div className="flex flex-row text-sm gap-x-2 font-light">
                    <GroupMentionData
                      totalEntries={taxonomyGroup.totalEntries}
                      relativeShare={taxonomyGroup.relativeShare}
                      relativeShareFull={taxonomyGroup.relativeShareFull}
                    />
                    <div className="text-gray-400">|</div>
                    <CreatedByIcon creator={taxonomyGroup.creator} dateCreated={taxonomyGroup.date} />
                  </div>
                )}
              </div>
            </div>
          </div>
          {taxonomyGroup.processing ? (
            <GroupBuildProgress progress={taxonomyGroup.progress} />
          ) : (
            <div className="flex flex-col">
              <div className="flex flex-row justify-end gap-x-1">
                <PinGroupLink pinnedByUser={taxonomyGroup.isPinnedByUser} pinLoading={pinLoading} onClick={(e) => handlePinGroup(e)} />
                <CopyGroupLink
                  onClick={(e) => {
                    e.stopPropagation();
                    copyLinkToClipboard();
                    writeToastMessage('Copied group link to clipboard');
                  }}
                />
                <GroupSettingsMenu
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveId(taxonomyGroup.id);
                  }}
                  settings={settings}
                />
              </div>
              <ProgressBar exportProgress={exportProgress} />
            </div>
          )}
        </div>
      </div>

      <div className={`ml-24 mt-2  ${taxonomyGroup.showChildren ? 'opacity-100 h-auto overflow-visible' : 'opacity-0 h-0 overflow-hidden'}`}>{children}</div>
    </>
  );
};

const CreatedByIcon = ({ creator, dateCreated }: { creator: Creator; dateCreated: number }) => {
  const isUnwrapGenerated = creator.isUnwrapGenerated || creator.creatorEmail === undefined;

  const getIcon = (isUnwrapGenerated: boolean) => {
    if (isUnwrapGenerated) {
      return <SparklesIcon className="h-4 w-4" />;
    } else {
      return <UserIcon className="h-4 w-4" />;
    }
  };

  return (
    <Tippy
      theme="light"
      content={
        <>
          <CreatedBy creator={creator} dateCreated={new Date(dateCreated)} />
        </>
      }
    >
      {getIcon(isUnwrapGenerated)}
    </Tippy>
  );
};
