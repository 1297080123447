import { FilterDispatchContext } from '../../../../context/filterStatementContext';
import { FilterGroupActionType, FilterNodeState, FilterStatementSchema } from '../../../../reducers/filterStatement/filterStatementReducer';
import { useState, useContext } from 'react';
import { FilterConfigurationPopup } from '../filterConfiguration/FilterConfiguration';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline';
import { FilterType } from '../../../../generated/graphql';

export const AppliedFilterActions: React.FC<{ filter: FilterNodeState }> = ({ filter }) => {
  const dispatch = useContext(FilterDispatchContext);
  const [isOpen, setIsOpen] = useState(false);

  const onEditNodeComplete = (node: FilterStatementSchema) => {
    /**
     * This is handling when someone clicks the '+' icon on an existing statement or collection
     * we want to add this new node to the current node
     */
    const payload =
      filter.type === 'collection'
        ? {
            id: filter.id,
            filterNode: {
              ...filter,
              items: [...filter.items, node],
            },
          }
        : {
            id: filter.id,
            filterNode: {
              type: 'collection',
              operator: FilterType.And,
              items: [filter, node],
            },
          };

    dispatch({
      type: FilterGroupActionType.UpdateFilterNode,
      payload,
    });
  };

  return (
    <div className="before:absolute before:inset-0 before:rounded-3xl before:opacity-0 before:transition-opacity before:duration-200 before:bg-gradient-to-r before:from-gray-200/80 before:via-gray-200/100 before:to-gray-200 before:-z-10 group-hover:before:opacity-100 z-50 pl-1 pr-1 top-0 lg:pt-0 right-0 absolute h-full lg:bottom-0 flex flex-row items-start lg:items-center">
      <div className="rounded-r-3xl flex flex-row items-center">
        <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          <button
            name="delete-button"
            className="hover:text-raspberry hover:bg-gray-300 duration-100 rounded-xl p-1"
            onClick={() => {
              dispatch({
                type: FilterGroupActionType.RemoveFilterNode,
                payload: {
                  id: filter.id,
                },
              });
            }}
          >
            <TrashIcon className="w-4 h-4" />
          </button> 
        </div>
        <div className="inline-block">
          <FilterConfigurationPopup onComplete={onEditNodeComplete}>
            <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              <button name="plus-button" className="hover:text-raspberry hover:bg-gray-300 duration-100 rounded-xl p-1">
                <PlusIcon className="w-4 h-4" />
              </button>
            </div>
          </FilterConfigurationPopup> 
        </div>
      </div>
    </div>
  );
};
