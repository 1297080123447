import { DataSeriesConfiguration } from '../../../generated/graphql';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { VirtualizedComboBox } from '../VirtualizedComboBox';
import Tippy from '@tippyjs/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useChartDispatch } from '../../../context/chartContext';
import { ChartActionType } from '../../../reducers/charts/chartReducer';
import FilterBar from '../filters/filterBar/FilterBar';
import { useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { FilterContext, FilterDispatchContext } from '../../../context/filterStatementContext';
import { filterStatementReducer } from '../../../reducers/filterStatement/filterStatementReducer';
import { InitialFilterStateProvider } from '../../filters/utilities/InitialFilterStateProvider';

export const SeriesConfiguration = ({
  teamsList,
  defaultTeamId,
  index,
  series,
}: {
  teamsList: IDropDownItem[];
  defaultTeamId: number;
  series: DataSeriesConfiguration;
  index: number;
}) => {
  const teamId = series.teamIdOverride ?? defaultTeamId;
  // the initial state needs to come from the series.filterNode
  // there's a weird loop here where the filterState is updated and then the series.filterNode is updated which triggers the a dispatch and state Change
  // to the series.filterNode which likely triggers the filterState to be updated again.

  const initialState = useMemo(
    () => InitialFilterStateProvider.getStateFromFilterNode(series.filterNode, series.teamIdOverride ?? defaultTeamId),
    [series.teamIdOverride]
  );

  const [filterState, dispatch] = useReducer(filterStatementReducer, initialState);

  // we might need to manually update if the initial state changes
  // this brings up a bigger question though. How do we go from a serialized filterNode back to the filterState?

  return (
    <FilterContext.Provider value={filterState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <SeriesConfigurationCard teamsList={teamsList} teamId={teamId} index={index} />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

const SeriesConfigurationCard = ({
  teamsList,
  teamId,
  index,
}: {
  teamsList: IDropDownItem[];
  /**
   * This is the teamId that the series represents
   */
  teamId: number;
  index: number;
}) => {
  const chartDispatch = useChartDispatch();
  const filterState = useContext(FilterContext);

  const isFirstRender = useRef(true);

  useEffect(() => {
    /**
     * We need to make sure this useEffect does not run on the first render of the page.
     *
     * The reason is this will create a cycle in the page load. We load in the chart state, get to this component and set the filter node on the series, this will trigger a refetch because we think the
     * filter node has changed when in reality it hasn't and this was just loading in the initial state.
     */
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    // we need to dispatch this to the chart state so that the plot can be updated when you change the filter
    // this is causing an initial rerender cycle for the chart which is pretty strange
    chartDispatch({ type: ChartActionType.SetSeriesFilterNode, payload: { index, filterNode: filterState.filterConsumable } });
  }, [filterState.filterConsumable]);

  return (
    <div className="chart-data-card w-full p-4">
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-row items-center gap-x-3 min-w-0">
          <div className="flex-1 min-w-[200px]">
            <VirtualizedComboBox
              comboBoxData={teamsList}
              disableClear
              disableAlphabeticalSort
              setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
                if (!selectedItem) return;
                chartDispatch({ type: ChartActionType.SetSeriesTeamId, payload: { teamId: Number(selectedItem.id), index } });
              }}
              selectedItem={teamsList.find((item: any) => item.id === teamId)}
            />
          </div>
          {/*   <div className="flex-initial flex items-center">
          <FilterBar displayAsButton={false} />
        </div> */}
          {index > 0 && (
            <div className="flex-none">
              <Tippy theme="dark" content={<p>Remove current data series from chart</p>}>
                <div
                  className="remove-chart-data-series cursor-pointer"
                  onClick={() => {
                    chartDispatch({ type: ChartActionType.RemoveSeries, payload: { index } });
                  }}
                >
                  <XMarkIcon className="h-5 w-5 text-gray-500" />
                </div>
              </Tippy>
            </div>
          )}
        </div>
        <FilterBar />
      </div>
    </div>
  );
};
