import { FilterNodeState } from '../../../../reducers/filterStatement/filterStatementReducer';
import { OperatorSelector } from '../filterBar/RootFilter';
import { AppliedFilterActions } from './AppliedFilterActions';
import { AppliedStatementFilter } from './AppliedStatementFilter';

export const AppliedCollectionFilter: React.FC<{ filter: FilterNodeState }> = ({ filter }) => {
  if (filter.type !== 'collection') throw new Error('AppliedCollectionFilter must be a collection');

  return (
    <div className={`flex flex-row gap-x-1.5 group items-center`}>
      {filter.items.map((item, index) => (
        <>
          {index > 0 && (
            <div className="flex flex-row items-center relative h-4/6 px-1 bg-silver-lighter bg-opacity-60">
              <OperatorSelector filter={filter} />
            </div>
          )}

          {item.type === 'statement' && <AppliedStatementFilter filter={item} />}
        </>
      ))}
      <AppliedFilterActions filter={filter} />
    </div>
  );
};
